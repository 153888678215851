import { Dictionary } from '../types/dictionary';

export function parseUrlPart(queryString: string) {
	if (!queryString) {
		return {};
	}

	// Replace leading '?'
	const cleanedStr = queryString.replace(/^(\?|#)/, '');

	// Split into individual key-value pairs
	const kvPairs = cleanedStr.split('&');

	return kvPairs.reduce((accumulator, kvPairString) => {
		const index = kvPairString.indexOf('=');
		let key: string;
		let value = '';
		if (index < 0) {
			key = kvPairString;
		} else {
			key = kvPairString.substring(0, index);
			value = kvPairString.substring(index + 1);
		}
		key = decodeURIComponent(key);
		value = decodeURIComponent(value);

		return {
			...accumulator,
			[key]: value
		};
	}, {} as Dictionary<string>);
}

export const parseQueryString = parseUrlPart(window.location.search);
export const parseHashString = parseUrlPart(window.location.hash);
