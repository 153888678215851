import { Dictionary } from '../types/dictionary';
import { Environment } from './environments';
import { Products } from './products';

// Student URLS
const NATIVE_URLS: Dictionary<Dictionary<string>> = {
	[Environment.Development]: {
		[Products.ILE]: 'imaginelearning://?loginstep=productselected&product=ILL',
		[Products.Spanish]: 'imaginelearning://?loginstep=productselected&product=IE',
		[Products.IEBenchmarkPlayerLink]: 'imaginelearning://?loginstep=benchmarkrequired&redirecturl={benchmarkUrl}'
	},
	[Environment.Test]: {
		[Products.ILE]: 'imaginelearning://?loginstep=productselected&product=ILL',
		[Products.Spanish]: 'imaginelearning://?loginstep=productselected&product=IE',
		[Products.IEBenchmarkPlayerLink]: 'imaginelearning://?loginstep=benchmarkrequired&redirecturl={benchmarkUrl}'
	},
	[Environment.Rc]: {
		[Products.ILE]: 'imaginelearning://?loginstep=productselected&product=ILL',
		[Products.Spanish]: 'imaginelearning://?loginstep=productselected&product=IE',
		[Products.IEBenchmarkPlayerLink]: 'imaginelearning://?loginstep=benchmarkrequired&redirecturl={benchmarkUrl}'
	},
	[Environment.Production]: {
		[Products.ILE]: 'imaginelearning://?loginstep=productselected&product=ILL',
		[Products.Spanish]: 'imaginelearning://?loginstep=productselected&product=IE',
		[Products.IEBenchmarkPlayerLink]: 'imaginelearning://?loginstep=benchmarkrequired&redirecturl={benchmarkUrl}'
	}
};

export default NATIVE_URLS;
