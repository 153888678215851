// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serializable<T>(obj: any) {
	if (typeof obj !== 'object') {
		return obj as T;
	}
	let keys = Object.getOwnPropertyNames(obj).sort();
	if (!keys.length) {
		keys = Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).sort();
	}
	if (keys.length) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return keys.reduce((o, key) => ({ ...o, [key]: (obj as any)[key] }), {}) as T;
	}
	return JSON.parse(JSON.stringify(obj)) as T;
}

export function stringifyError(error: Error | object | string) {
	if (!error) {
		return '';
	}
	if (typeof error === 'object') {
		const errorObj = serializable(error);
		return JSON.stringify(errorObj, null, '    ').replace(/\\n/g, '\n');
	}
	return error;
}
