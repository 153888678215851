export enum UserFlags {
	AutoLaunchGalileo = 'kd9jy5cfn9i47y613', //Auto-Launch-Assessment in Flagr
	RedirectSpanishWhenPendingAssessment = 'RedirectSpanishWhenPendingAssessment',
	ResetBanner = 'ResetBanner',
	EnableRobotify = 'EnableRobotify',
	EnableNativeApp = 'EnableNativeApp',
	EnableReadOnlyILSiteAdminRedirectToMaestro = 'EnableReadOnlyILSiteAdminRedirectToMaestro'
}

export enum GlobalFlags {}
