export enum ApiServices {
	Auth = 'auth',
	Manager = 'manager',
	Authority = 'authority',
	Redirect = 'redirect',
	PostLogoutRedirect = 'postLogoutRedirect',
	ILU = 'ilu',
	Permissions = 'permissions',
	Flagr = 'flagr',
	Logger = 'logger',
	Imlp = 'imlp',
	Import = 'import',
	Caliper = 'caliper',
	ContextEnvironment = 'contextenvironment',
	CustomerToolsUI = 'customerToolsUI',
	Registration = 'registration',
	Maestro = 'maestro'
}
