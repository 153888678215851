import classnames from 'classnames';
import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

interface LoaderProps {
	color?: string;
	className?: string;
	loading?: boolean;
}

function Loader({ color = '#0096d9', className, loading }: LoaderProps) {
	return loading === true ? (
		<div className={classnames('Loader', className)}>
			<PulseLoader color={color} margin={'5px'} loading={true} />
		</div>
	) : null;
}

export default Loader;
