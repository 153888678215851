import { ProductPermissionsProduct } from '../services/permissions';
import { Dictionary } from '../types/dictionary';
import { PRODUCT_NAMES } from './productNames';
import { Products } from './products';

export interface FakeProduct extends ProductPermissionsProduct {
	description: string;
}

export const FakeProducts: Dictionary<FakeProduct> = {
	[Products.ILE]: {
		product: Products.ILE,
		description: PRODUCT_NAMES.ILE,
		status: 'Enabled',
		assessmentLockout: {
			status: 'None',
			provider: 'ATI',
			url: 'https://aws-dev.ati-online.com/StudentCenter/ILToGalileoRedirect/Index'
		}
	} as FakeProduct,
	[Products.Spanish]: {
		product: Products.Spanish,
		description: PRODUCT_NAMES.Spanish,
		status: 'Disabled',
		assessmentLockout: {
			status: 'None',
			provider: 'ATI',
			url: 'https://aws-dev.ati-online.com/StudentCenter/ILToGalileoRedirect/Index'
		}
	} as FakeProduct,
	[Products.Reader]: {
		product: Products.Reader,
		description: PRODUCT_NAMES.Reader,
		status: 'Enabled'
	} as FakeProduct,
	[Products.AdaptiveAssessment]: {
		product: Products.AdaptiveAssessment,
		description: PRODUCT_NAMES.AdaptiveAssessment,
		status: 'Enabled'
	} as FakeProduct,
	[Products.MyPathMath]: {
		product: Products.MyPathMath,
		description: PRODUCT_NAMES.MyPathMath,
		status: 'Enabled'
	} as FakeProduct,
	[Products.MyPathReading]: {
		product: Products.MyPathReading,
		description: PRODUCT_NAMES.MyPathReading,
		status: 'Enabled'
	} as FakeProduct,
	[Products.Traverse]: {
		product: Products.Traverse,
		description: PRODUCT_NAMES.Traverse,
		status: 'Enabled'
	} as FakeProduct
};

export default FakeProducts;
