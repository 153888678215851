import './polyfills'; // MUST BE FIRST LINE
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './fonts/fonts.scss';
import './index.scss';
import store from './redux/configureStore';
import { storeRehydrated } from './redux/modules/loginForm';
import { unregister } from './serviceWorker';

const persistor = persistStore(store, undefined, () => store.dispatch(storeRehydrated()));

const history = createBrowserHistory();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<Router history={history}>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

Modal.setAppElement('#root');

unregister();
