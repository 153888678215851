import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';

export function selectAutoFillValues(state: RootState) {
	return state.loginForm.autoFillValues;
}

export function selectError(state: RootState) {
	return state.loginForm.error;
}

export function selectForgotSiteCodeError(state: RootState) {
	return state.loginForm.forgotSiteCodeError;
}

export function selectForgotSiteCodeLoading(state: RootState) {
	return state.loginForm.forgotSiteCodeLoading;
}

export function selectInvalidCredentials(state: RootState) {
	return state.loginForm.invalidCredentials;
}

export function selectTooManyAttempts(state: RootState) {
	return state.loginForm.tooManyAttempts;
}

export function selectDurationSecs(state: RootState) {
	return state.loginForm.durationSecs;
}

export function selectIsEducator(state: RootState) {
	return state.loginForm.isEducator;
}

export function selectSiteCode(state: RootState) {
	return state.loginForm.siteCode;
}

export function selectLoginType(state: RootState) {
	return state.loginForm.loginType;
}

export function selectSiteCodeEditable(state: RootState) {
	return state.loginForm.siteCodeEditable;
}

export function selectUsername(state: RootState) {
	return state.loginForm.username;
}

export const selectForgotSiteCodeNoValues = createSelector([selectForgotSiteCodeRawValues], values => values === false);

export const selectForgotSiteCodeValues = createSelector([selectForgotSiteCodeRawValues], values =>
	values === false ? undefined : (values as string[])
);

/**
 * Private selectors
 */

function selectForgotSiteCodeRawValues(state: RootState) {
	return state.loginForm.forgotSiteCodeValues;
}
