import { Dictionary } from '../types/dictionary';
import { Products } from './products';

export const PRODUCT_NAMES: Dictionary<string> = {
	[Products.Galileo]: 'Imagine Assessment: Galileo',
	[Products.ILE]: 'Imagine Language & Literacy',
	[Products.IM]: 'Imagine Math',
	[Products.IMF]: 'Imagine Math Facts',
	[Products.Reader]: 'Imagine Reading',
	[Products.Spanish]: 'Imagine Español',
	[Products.Lectura]: 'Imagine Lectura',
	[Products.MyPathMath]: 'Imagine MyPath Math',
	[Products.MyPathReading]: 'Imagine MyPath Reading',
	[Products.Science]: 'Imagine MyPath Science',
	[Products.Purpose]: 'Imagine Purpose Prep',
	[Products.Traverse]: 'Imagine Traverse',
	[Products.Robotify]: 'Robotify'
};
