import { AnalyticsUser, Organization } from '../types/analyticsUser';
import { PendoConfig } from '../types/pendo';
import { Product } from '../types/product';
import pendoSnippet from './pendoSnippet';

export function initSnippet(apiKey?: string) {
	if (!apiKey) {
		return false;
	}

	pendoSnippet(apiKey)(window, document, 'script', 'pendo');

	return true;
}

export const initStudentSnippet = () => initSnippet(process.env.REACT_APP_PENDO_API_KEY_STUDENT);

export const initEducatorSnippet = () => initSnippet(process.env.REACT_APP_PENDO_API_KEY_EDUCATOR);

function getStudentInfo(analyticsUser: AnalyticsUser): PendoConfig {
	const organization =
		analyticsUser && analyticsUser.organizations && analyticsUser.organizations.length
			? analyticsUser.organizations[0]
			: ({} as Organization);
	return {
		visitor: {
			id: analyticsUser.id,
			firstName: analyticsUser.firstName,
			lastName: analyticsUser.lastName,
			userName: analyticsUser.username,
			source: analyticsUser.source,
			role: analyticsUser.userType || 'Student',
			gradeLevel: analyticsUser.gradeLevel,
			products: analyticsUser.userProducts || [],
			realm: analyticsUser.realm || '',
			ssoid: analyticsUser.ssoid || ''
		},
		account: {
			id: organization.id,
			siteCode: analyticsUser.sites || ''
		}
	};
}

function getEducatorInfo(analyticsUser: AnalyticsUser, products: Product[], resetDate?: string): PendoConfig {
	const organization =
		analyticsUser && analyticsUser.organizations && analyticsUser.organizations.length
			? analyticsUser.organizations[0]
			: ({} as Organization);
	return {
		visitor: {
			id: analyticsUser.id,
			firstName: analyticsUser.firstName,
			lastName: analyticsUser.lastName,
			userName: analyticsUser.username,
			source: analyticsUser.source,
			role: analyticsUser.userType || 'Teacher',
			//educators don't have userProducts, so we use the products passed in
			products: products?.filter(p => p.enabled)?.map(p => p.id) || [],
			realm: analyticsUser.realm || '',
			ssoid: analyticsUser.ssoid || '',
			resetDate
		},
		account: {
			id: organization.id,
			siteCode: organization.siteCode || analyticsUser.sites || ''
		}
	};
}

function getUserInfo(analyticsUser: AnalyticsUser, products: Product[] = [], resetDate?: string) {
	if (!analyticsUser) {
		return undefined;
	}

	return analyticsUser.userType && analyticsUser.userType.toUpperCase() === 'STUDENT'
		? getStudentInfo(analyticsUser)
		: getEducatorInfo(analyticsUser, products, resetDate);
}

export function identify(analyticsUser: AnalyticsUser, products: Product[] = [], resetDate?: string) {
	const args = getUserInfo(analyticsUser, products, resetDate);

	let retryTimeout: NodeJS.Timeout;
	const tryIdentify = (count = 0) => {
		const instance = window.pendo;
		if (instance) {
			instance.initialize(args);
		} else if (count < 5) {
			retryTimeout = setTimeout(() => tryIdentify(count + 1), 100 * count);
		} else {
			console.error('Unable to obtain global instance of pendo');
		}
	};
	tryIdentify();

	return () => clearTimeout(retryTimeout);
}

export function updateOptions(analyticsUser: AnalyticsUser, products: Product[] = [], resetDate?: string) {
	const args = getUserInfo(analyticsUser, products, resetDate);
	const instance = window.pendo;
	if (instance) {
		instance.updateOptions(args);
	}
}
