import http from './http';
import { UserFlags, GlobalFlags } from '../constants/flags';
import { EMPTY, Observable } from 'rxjs';
import { getFlagrUrl, getEnvironment } from './network';
import { Environment } from '../constants/environments';

export const API_TIMEOUT_MS = 3000;

export interface EntityContext<T> {
	[Key: string]: T;
}

export interface EvaluateBatchResponse {
	evaluationResults: EvaluationResult[];
}

export interface EvaluationResult {
	flagID: number;
	flagKey: string;
	segmentID: number;
	variantAttachment: string;
	variantID: number;
	variantKey: string;
}

export function evaluateUserBatch(context: EntityContext<string>, id?: string, sitecode?: string): Observable<EvaluateBatchResponse> {
	const flagKeys = Object.values(UserFlags);
	if (flagKeys.length === 0) return EMPTY;

	const url = `${getFlagrUrl(getEnvironment(sitecode))}api/v1/evaluation/batch`;
	const body = {
		entities: [{ entityId: id, entityType: 'student', entityContext: context }],
		enableDebug: true,
		flagKeys: flagKeys
	};

	const bodyText = JSON.stringify(body);
	return http(url)
		.body(bodyText)
		.contentType('application/json')
		.post();
}

export function evaluateGlobalBatch(): Observable<EvaluateBatchResponse> {
	const flagKeys = Object.values(GlobalFlags);
	if (flagKeys.length === 0) return EMPTY;

	const env = getEnvironment();
	const url = `${getFlagrUrl(env)}api/v1/evaluation/batch`;
	const body = {
		entities: [{ siteCode: 'any' }],
		enableDebug: env !== Environment.Production,
		flagKeys: flagKeys
	};

	const bodyText = JSON.stringify(body);
	return http(url)
		.body(bodyText)
		.contentType('application/json')
		.post();
}
