import { ApiServices } from '../constants/apiServices';
import http from './http';
import { getEnvironment, getManagerUrl, getServiceVersion } from './network';

const environment = getEnvironment();
const version = getServiceVersion(ApiServices.Manager, environment);
const baseUrl = `${getManagerUrl(environment)}/${version}/passwordReset`;

export function setPassword(token: string, password: string) {
	return http(`${baseUrl}/${token}`)
		.body({ password })
		.post();
}

export function sendResetEmail(username: string) {
	return http(baseUrl)
		.body({ username })
		.post();
}

export function validateResetToken(token: string) {
	return http(`${baseUrl}/${token}`).get<{ username: string }>();
}
