import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Dictionary } from '../../types/dictionary';
import { ReturnUrl } from '../../types/returnUrl';
import { evaluateGlobalBatch, EvaluationResult } from '../../services/flagr';
import { RootStateOrAny } from 'react-redux';

/**
 * Action creators
 */

export const appError = createAction('universal-login-page/app/ERROR');
export const loadedSiteCode = createAction('universal-login-page/app/LOADED_SITECODE');
export const selectedProduct = createAction('universal-login-page/app/SELECTED_PRODUCT');
export const setReturnUrl = createAction<string | undefined>('universal-login-page/app/SET_RETURN_URL');
export const storeProductQueryParams = createAction('universal-login-page/app/HAD_PRODUCT_QUERY_STRING');
export const tokenReceived = createAction<string>('universal-login-page/app/TOKEN_RECEIVED');
export const fetchingGlobalFlags = createAction('universal-login-page/app/FETCHING_GLOBAL_FLAGS');
export const globalFlagEvaluationComplete = createAction<EvaluationResult[]>('universal-login-page/app/GLOBAL_FLAG_EVALUATION_COMPLETE');

export function clearReturnUrl() {
	return setReturnUrl();
}

/**
 * Reducer
 */

export interface AppState {
	access_token?: string;
	error?: Error | object;
	params?: Dictionary<string | undefined>;
	productQueryStrings?: Dictionary<string>;
	returnUrl?: ReturnUrl;
	flagsLoaded: boolean;
	evaluationResults?: EvaluationResult[];
}

const initialState: AppState = {
	flagsLoaded: false
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(tokenReceived, (state, action) => ({
			...state,
			// eslint-disable-next-line @typescript-eslint/camelcase
			access_token: action.payload
		}))
		.addCase(selectedProduct, (state, action) => ({
			...state,
			selectedProduct: action.payload
		}))
		.addCase(appError, (state, action) => ({
			...state,
			error: action.payload
		}))
		.addCase(loadedSiteCode, (state, action) => {
			// If sitecode is already set, just ignore this
			if (state.params && state.params.sitecode) {
				return state;
			}
			const sitecode = action.payload;
			const params: Dictionary<string | undefined> = state.params
				? {
						...state.params,
						sitecode
				  }
				: { sitecode };
			return {
				...state,
				params
			};
		})
		.addCase(storeProductQueryParams, (state, action) => ({
			...state,
			productQueryStrings: action.payload || {}
		}))
		.addCase(setReturnUrl, (state, action) => {
			const url = action.payload;
			const returnUrl = (url && { url, expires: Date.now() + 30000 }) || undefined;

			return {
				...state,
				returnUrl
			};
		})
		.addCase(fetchingGlobalFlags, state => ({
			...state,
			flagsLoaded: false
		}))
		.addCase(globalFlagEvaluationComplete, (state, action) => {
			return {
				...state,
				flagsLoaded: true,
				evaluationResults: action.payload
			};
		})
);

export default reducer;

/**
 * Epics
 */

export function getGlobalFlagsEpic(action$: Observable<PayloadAction<{}>>, state$: StateObservable<RootStateOrAny>) {
	return action$.pipe(
		ofType(fetchingGlobalFlags.type),
		withLatestFrom(state$),
		switchMap(([_, state]) => {
			// Failure to evaluate is actually okay, and shouldn't block progress at all.
			return evaluateGlobalBatch().pipe(
				map(({ evaluationResults }) => globalFlagEvaluationComplete(evaluationResults)),
				catchError(async error => {
					const emptyResults: EvaluationResult[] = [];
					console.error('error retrieving flags');
					return globalFlagEvaluationComplete(emptyResults);
				})
			);
		})
	);
}
