import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserFlagEvaluationResults } from '../redux/selectors/productSelection';
import { selectGlobalFlagEvaluationResults } from '../redux/selectors/app';

function useFlagr(key: string) {
	const userFlags = useSelector(selectUserFlagEvaluationResults);
	const globalFlags = useSelector(selectGlobalFlagEvaluationResults);
	const flag = useMemo(() => userFlags?.find(({ flagKey }) => flagKey === key) ?? globalFlags?.find(({ flagKey }) => flagKey === key), [
		userFlags,
		globalFlags,
		key
	]);
	return flag || null;
}

export default useFlagr;
