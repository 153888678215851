import { createSelector } from '@reduxjs/toolkit';
import { Environment } from '../../constants/environments';
import decodeJwt from '../../services/jwt';
import { RootState } from '../root';

export function selectAccessToken(state: RootState) {
	return state.app.access_token;
}

export function selectError(state: RootState) {
	return state.app.error;
}

export function selectParams(state: RootState) {
	return state.app.params;
}

export function selectProductQueryStrings(state: RootState) {
	return state.app.productQueryStrings;
}

export function selectReturnUrl(state: RootState) {
	return state.app.returnUrl;
}

export const selectUser = createSelector([selectAccessToken], token => {
	if (!token) {
		return undefined;
	}
	return decodeJwt(token);
});

export const selectEnvironment = createSelector([selectUser], user => {
	const env = user?.environment;
	if (!env) {
		return Environment.Development;
	}
	switch (env) {
		case 'dev':
		case 'qa':
			return Environment.Test;
		case 'staging':
			return Environment.Rc;
		case 'prod':
			return Environment.Production;
		default:
			return env as Environment;
	}
});

export const selectImpersonator = createSelector([selectUser], user => user?.impersonator);

export const selectRole = createSelector([selectUser], user => user?.role);

export const selectTenant = createSelector([selectUser], user => user?.tenant);

export const selectStudentId = createSelector([selectUser], user => user?.studentId);

export const selectUserName = createSelector([selectUser], user => user?.userName);

export function selectGlobalFlagEvaluationResults(state: RootState) {
	return state.app.evaluationResults;
}
