import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app, { getGlobalFlagsEpic } from './modules/app';
import loginForm, { lookupSiteCodeEpic, validateSiteCodeEpic } from './modules/loginForm';
import oidc, { completeSigninEpic, handlePostLogoutEpic, signinErrorEpic } from './modules/oidc';
import productLauncher, {
	launchProductAfterCaliperEpic,
	launchProductAtUrlEpic,
	launchProductEpic,
	requestOneTimeTokenEpic
} from './modules/productLauncher';
import productSelection, {
	getProductsAfterFlagsEpic,
	getUserFlagsEpic,
	analyticsIdentifyUserEpic,
	analyticsUpdateOptionsEpic
} from './modules/productSelection';
import resetPassword, {
	sendResetEmailEpic,
	setPasswordCompleteEpic,
	setPasswordEpic,
	validateResetTokenCallbackEpic,
	validateResetTokenEpic
} from './modules/resetPassword';
import webCam from './modules/webCam';
import caliperReducer, { caliperSessionEventEpic } from './modules/caliper';

export const rootEpic = combineEpics(
	completeSigninEpic,
	getUserFlagsEpic,
	analyticsIdentifyUserEpic,
	analyticsUpdateOptionsEpic,
	getProductsAfterFlagsEpic,
	handlePostLogoutEpic,
	launchProductAtUrlEpic,
	launchProductEpic,
	lookupSiteCodeEpic,
	requestOneTimeTokenEpic,
	sendResetEmailEpic,
	setPasswordCompleteEpic,
	setPasswordEpic,
	signinErrorEpic,
	validateResetTokenEpic,
	validateResetTokenCallbackEpic,
	validateSiteCodeEpic,
	getGlobalFlagsEpic,
	caliperSessionEventEpic,
	launchProductAfterCaliperEpic
);

export const rootReducer = combineReducers({
	app: persistReducer(
		{
			key: 'app',
			storage,
			whitelist: ['productQueryStrings', 'returnUrl']
		},
		app
	),
	loginForm: persistReducer(
		{
			key: 'loginForm',
			storage,
			whitelist: ['isEducator', 'siteCode', 'loginType']
		},
		loginForm
	),
	oidc,
	productLauncher,
	productSelection,
	resetPassword,
	webCam,
	caliperReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
