import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import * as Password from '../../services/password';
import { serializable } from '../../services/serializable';

/**
 * Action creators
 */

export const sendEmailError = createAction<Response | Error | object>('universal-login-page/resetPassword/RESET_PASSWORD_SEND_EMAIL_ERROR');
export const sendResetEmail = createAction<string>('universal-login-page/resetPassword/RESET_PASSWORD_SEND_EMAIL_REQUEST');
export const sendEmailSuccess = createAction('universal-login-page/resetPassword/RESET_PASSWORD_SEND_EMAIL_SUCCESS');
export const setPasswordComplete = createAction('universal-login-page/resetPassword/RESET_PASSWORD_SET_PASSWORD_COMPLETE');
export const setPasswordError = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_SET_PASSWORD_ERROR',
	(error: Response | Error | object, callback: () => void) => ({ meta: callback, payload: error })
);
export const setPassword = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_SET_PASSWORD_REQUEST',
	(token: string, password: string, callback: () => void) => ({
		meta: callback,
		payload: { token, password }
	})
);
export const setPasswordSuccess = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_SET_PASSWORD_SUCCESS',
	(callback: () => void) => ({ meta: callback, payload: undefined })
);
export const validateResetToken = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_VALIDATE_TOKENID_REQUEST',
	(tokenId: string, callback?: () => void) => ({ meta: callback, payload: tokenId })
);
export const validateResetTokenCallbackComplete = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_VALIDATE_TOKENID_CALLBACK_COMPLETE'
);
export const validateTokenIdError = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_VALIDATE_TOKENID_ERROR',
	(error: Response | Error | object, callback: () => void) => ({ meta: callback, payload: error })
);
export const validateTokenIdSuccess = createAction(
	'universal-login-page/resetPassword/RESET_PASSWORD_VALIDATE_TOKENID_SUCCESS',
	(username: string, callback: () => void) => ({ meta: callback, payload: username })
);

/**
 * Reducer
 */

export interface ResetPasswordState {
	resetError?: Response | Error | object;
	resetSubmitted: boolean;
	resetSuccess: boolean;
	resetTokenInvalid: boolean;
	sendEmailError?: Response | Error | object;
	sendEmailSubmitted: boolean;
	sendEmailSuccess: boolean;
}

const initialState: ResetPasswordState = {
	resetSubmitted: false,
	resetSuccess: false,
	resetTokenInvalid: false,
	sendEmailSubmitted: false,
	sendEmailSuccess: false
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(sendEmailError, (state, { payload }) => ({
			...state,
			sendEmailError: payload
		}))
		.addCase(sendResetEmail, (state, action) => ({
			...state,
			sendEmailSubmitted: true
		}))
		.addCase(sendEmailSuccess, (state, action) => ({
			...state,
			sendEmailSuccess: true
		}))
		.addCase(setPasswordError, (state, { payload }) => ({
			...state,
			resetError: payload
		}))
		.addCase(setPasswordSuccess, (state, action) => ({
			...state,
			resetSuccess: true
		}))
		.addCase(validateTokenIdError, (state, action) => ({
			...state,
			resetTokenInvalid: true
		}))
);

export default reducer;

/**
 * Epics
 */

export function setPasswordEpic(action$: Observable<PayloadAction<{ token: string; password: string }> & { meta: () => void }>) {
	return action$.pipe(
		ofType(setPassword.type),
		switchMap(({ meta: callback, payload }) => {
			const { token, password } = payload;
			return Password.setPassword(token, password).pipe(
				map(() => setPasswordSuccess(callback)),
				catchError(err => getErrorPayload(err).pipe(map(error => setPasswordError(error, callback))))
			);
		})
	);
}

export function setPasswordCompleteEpic(action$: Observable<PayloadAction & { meta: () => void }>) {
	return action$.pipe(
		filter(({ type }) => type === setPasswordSuccess.type || type === setPasswordError.type),
		tap(({ meta: callback }) => callback && callback()),
		map(() => setPasswordComplete())
	);
}

export function sendResetEmailEpic(action$: Observable<PayloadAction<string>>) {
	return action$.pipe(
		ofType(sendResetEmail.type),
		switchMap(({ payload: email }) => {
			return Password.sendResetEmail(email).pipe(
				map(() => sendEmailSuccess()),
				catchError(err =>
					err?.status === 404 ? of(sendEmailSuccess()) : getErrorPayload(err).pipe(map(payload => sendEmailError(payload)))
				)
			);
		})
	);
}

export function validateResetTokenEpic(action$: Observable<PayloadAction<string> & { meta: () => void }>) {
	return action$.pipe(
		ofType(validateResetToken.type),
		switchMap(({ meta: callback, payload: tokenId }) =>
			Password.validateResetToken(tokenId).pipe(
				map(({ username }) => validateTokenIdSuccess(username, callback)),
				catchError(err => getErrorPayload(err).pipe(map(payload => validateTokenIdError(payload, callback))))
			)
		)
	);
}

export function validateResetTokenCallbackEpic(action$: Observable<PayloadAction & { meta: () => void }>) {
	return action$.pipe(
		filter(({ type }) => type === validateTokenIdSuccess.type || type === validateTokenIdError.type),
		tap(({ meta: callback }) => {
			callback && callback();
		}),
		map(() => validateResetTokenCallbackComplete())
	);
}

/**
 * Helper functions
 */

function getErrorPayload(error: Response | Error | object) {
	if (error instanceof Response) {
		return of({ message: error.body, status: error.status });
	}
	if (error instanceof Error) {
		return of({ message: error.message, stack: error.stack });
	}
	return of(serializable<object>(error));
}
