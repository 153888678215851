import { Observable } from 'rxjs';
import { ApiServices } from '../constants/apiServices';
import { LogSources } from '../constants/logSources';
import http from './http';
import { getEnvironment, getLoggerUrl, getServiceVersion } from './network';

const environment = getEnvironment();
const version = getServiceVersion(ApiServices.Logger, environment);
const baseUrl = `${getLoggerUrl(environment)}/${version}/logs/`;

export function log(log: object | object[], logSource: LogSources = LogSources.Metrics) {
	const neededMetaData = { Source: logSource, TimeStamp: new Date().toISOString() };
	const body = Array.isArray(log) ? log.map(item => ({ ...item, ...neededMetaData })) : [{ ...log, ...neededMetaData }];
	const bodyText = JSON.stringify(body);
	return http(baseUrl)
		.body(bodyText)
		.post();
}
