import { ApiServices } from '../constants/apiServices';
import { Environment } from '../constants/environments';
import urls, { VERSIONS } from '../constants/urls';
import { UrlMap } from '../types/urlMap';
import { VersionMap } from '../types/versionMap';

export function getSiteCode(sitecode: string) {
	const parts = (sitecode || '').split('@');
	const newSiteCode = parts.slice(0, 1).shift();
	return newSiteCode || null;
}

export function getInferredEnvironment(host?: string) {
	// Allow override from environment variable
	if (process.env.REACT_APP_DEV_AUTH_ENV) {
		const env = process.env.REACT_APP_DEV_AUTH_ENV as Environment;
		if (env) {
			return env;
		}
	}

	// Check current URL
	const matches = (host || '').match(/^(local|lvh|test|rc|load|demo)/i);
	if (matches && matches.length > 1) {
		const match = matches[1].toLowerCase();
		if (match === 'local' || match === 'lvh') {
			return Environment.Development;
		}
		return match as Environment;
	}
	return Environment.Production;
}

export function getEnvironmentFn(inferredEnvironemnt?: Environment) {
	return (sitecode?: string) => {
		const parts = (sitecode || '').split('@');
		const env = parts.slice(1, 2).shift();
		return (env as Environment) || inferredEnvironemnt || Environment.Production;
	};
}

export const getEnvironment = getEnvironmentFn(getInferredEnvironment(window.location.host));

export function getUrlFromMap(urlMap: UrlMap) {
	return (service: string, environment?: Environment) => {
		const env = environment || Environment.Production;
		return (urlMap[env] || urlMap[Environment.Production])[service];
	};
}

export const getUrl = getUrlFromMap(urls);

export function getServiceVersionFromMap(versionMap: VersionMap) {
	return (service: string, environment?: Environment) => {
		const env = environment || Environment.Production;
		return (versionMap[env] || versionMap[Environment.Production])[service];
	};
}

export const getServiceVersion = getServiceVersionFromMap(VERSIONS);

export function getAuthUrl(environment: Environment) {
	return getUrl(ApiServices.Auth, environment);
}

export function getManagerUrl(environment: Environment) {
	return getUrl(ApiServices.Manager, environment);
}

export function getAuthorityUrl(environment: Environment) {
	return getUrl(ApiServices.Authority, environment);
}

export function getRedirectUrl(environment: Environment) {
	return process.env.REACT_APP_DEV_REDIRECT_URI || getUrl(ApiServices.Redirect, environment);
}

export function getPostLogoutRedirect(environment: Environment) {
	return getUrl(ApiServices.PostLogoutRedirect, environment);
}

export function getIluUrl(environment: Environment) {
	return getUrl(ApiServices.ILU, environment);
}

export function getFlagrUrl(environment: Environment) {
	return getUrl(ApiServices.Flagr, environment);
}

export function getLoggerUrl(environment: Environment) {
	return getUrl(ApiServices.Logger, environment);
}

export function getImlpUrl(environment: Environment) {
	return getUrl(ApiServices.Imlp, environment);
}
