import { Observable } from 'rxjs';
import { ApiServices } from '../constants/apiServices';
import { AssessmentProviders } from '../constants/assessmentProviders';
import { ProductDisabledReasons } from '../constants/productDisabledReasons';
import { Products } from '../constants/products';
import http from './http';
import { getEnvironment, getServiceVersion, getUrl } from './network';

const environment = getEnvironment();
const version = getServiceVersion(ApiServices.Permissions, environment);
const baseUrl = `${getUrl(ApiServices.Permissions, environment)}/${version}/permissions`;

export interface AssessmentLockout {
	status?: string;
	provider?: AssessmentProviders;
	url?: string;
}

export interface ProductPermissionsAssessmentRecommended {
	provider: AssessmentProviders;
	url: string;
}

export interface ProductPermissionsProduct {
	assessmentLockout?: AssessmentLockout;
	disabledReason?: ProductDisabledReasons;
	product: Products;
	status?: string;
	url?: string;
}

export interface ProductPermissionsResponse {
	assessmentRecommended?: ProductPermissionsAssessmentRecommended;
	products: ProductPermissionsProduct[];
	setupHelp?: ProductPermissionsSetupHelp;
}

export interface ProductPermissionsSetupHelp {
	rosterDashboard: Products;
	scheduleDashboard: Products;
}

export function getProductPermissions(userId: string, token: string): Observable<ProductPermissionsResponse> {
	const url = `${baseUrl}/user/${userId}/products`;
	return http(url)
		.bearer(token)
		.get<ProductPermissionsResponse>();
}
