import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { error as loginFormError, lookupSiteCode } from './modules/loginForm';
import { signinError } from './modules/oidc';
import { analyticsError, createAnalyticsMiddleware, productPermissionsError } from './modules/productSelection';
import {
	setPassword,
	setPasswordError,
	setPasswordSuccess,
	validateResetToken,
	validateTokenIdError,
	validateTokenIdSuccess
} from './modules/resetPassword';
import { rootEpic, rootReducer } from './root';

const analyticsMiddleware = createAnalyticsMiddleware();
const epicMiddleware = createEpicMiddleware();
const defaultMiddleware = getDefaultMiddleware({
	serializableCheck: {
		ignoredActions: [
			// 👇 These are from a 3rd party library, so we'll just have to ignore them.
			'persist/PERSIST',
			'persist/REHYDRATE',
			// 👇 These contain a callback used by middleware, but not serialized in the store.
			lookupSiteCode.type,
			setPassword.type,
			setPasswordError.type,
			setPasswordSuccess.type,
			validateResetToken.type,
			validateTokenIdError.type,
			validateTokenIdSuccess.type,
			// 👇 These may contain an error object.
			analyticsError.type,
			loginFormError.type,
			productPermissionsError.type,
			signinError.type
		]
	}
});
const middleware = [analyticsMiddleware, epicMiddleware, ...defaultMiddleware];

const reduxStore = configureStore({
	reducer: rootReducer,
	middleware
});

epicMiddleware.run(rootEpic);

export default reduxStore;
