import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import Auth from '../../services/auth';

/**
 * Action creators
 */

export const clearAutoFillValues = createAction('universal-login-page/loginForm/FORM_CLEAR_AUTO_FILL_VALUES');
export const clearError = createAction('universal-login-page/loginForm/FORM_CLEAR_ERROR');
export const editSiteCode = createAction('universal-login-page/loginForm/FORM_EDIT_SITECODE');
export const error = createAction<Error | object>('universal-login-page/loginForm/FORM_ERROR');
export const forgotSiteCodeError = createAction<Response | Error>('universal-login-page/loginForm/FORGOT_SITECODE_ERROR');
export const forgotSiteCodeReceived = createAction<{ sitecodes: string[] | null }>(
	'universal-login-page/loginForm/FORGOT_SITECODE_RECEIVED'
);
export const lookupSiteCode = createAction(
	'universal-login-page/loginForm/FORGOT_SITECODE_REQUEST',
	(email: string, callback?: () => void) => {
		return { payload: { email }, meta: callback };
	}
);
export const revertSiteCode = createAction('universal-login-page/loginForm/FORM_REVERT_SITECODE');
export const setAutoFillValues = createAction(
	'universal-login-page/loginForm/FORM_SET_AUTO_FILL_VALUES',
	(username: string, password: string, siteCode: string) => ({
		payload: { username, password, siteCode }
	})
);
export const setInvalidCredentials = createAction<boolean>('universal-login-page/loginForm/FORM_INVALID_CREDENTIALS');
export const setTooManyAttempts = createAction<boolean>('universal-login-page/loginForm/FORM_TOO_MANY_ATTEMPTS');
export const setDurationSecs = createAction<number>('universal-login-page/loginForm/FORM_DURATION_SECS');
export const setIsEducator = createAction<boolean>('universal-login-page/loginForm/FORM_SET_IS_EDUCATOR');
export const storeRehydrated = createAction('universal-login-page/loginForm/STORE_REHYDRATED');
export const updateSiteCode = createAction<string>('universal-login-page/loginForm/FORM_UPDATE_SITECODE');
export const updateLoginType = createAction<string>('universal-login-page/loginForm/FORM_UPDATE_LOGIN_TYPE');
export const updateUsername = createAction<string>('universal-login-page/loginForm/FORM_UPDATE_USERNAME');
export const validateSiteCode = createAction('universal-login-page/loginForm/FORM_VALIDATE_SITECODE');

/**
 * Reducer
 */

export interface LoginFormState {
	autoFillValues?: { password: string; siteCode: string; username: string };
	error?: Error | object;
	forgotSiteCodeError?: Response | Error | object;
	forgotSiteCodeLoading: boolean;
	forgotSiteCodeValues?: string[] | boolean;
	invalidCredentials: boolean;
	tooManyAttempts: boolean;
	durationSecs: number;
	isEducator: boolean;
	siteCode?: string;
	siteCodeEditable: boolean;
	username?: string;
	loginType?: string;
}

const initialState: LoginFormState = {
	forgotSiteCodeLoading: false,
	invalidCredentials: false,
	tooManyAttempts: false,
	durationSecs: 0,
	isEducator: false,
	siteCodeEditable: true
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(clearAutoFillValues, state => ({ ...state, autoFillValues: undefined }))
		.addCase(storeRehydrated, (state, action) => {
			const siteCodeEditable = !state.siteCode;
			return {
				...state,
				siteCodeEditable
			};
		})
		.addCase(lookupSiteCode, (state, action) => ({
			...state,
			forgotSiteCodeError: undefined,
			forgotSiteCodeLoading: true,
			forgotSiteCodeValues: undefined
		}))
		.addCase(forgotSiteCodeError, (state, { payload }) => ({
			...state,
			forgotSiteCodeLoading: false,
			forgotSiteCodeError: payload
		}))
		.addCase(forgotSiteCodeReceived, (state, { payload }) => {
			const { sitecodes } = payload;
			const forgotSiteCodeValues = sitecodes && sitecodes.length ? sitecodes : false;
			return {
				...state,
				forgotSiteCodeLoading: false,
				forgotSiteCodeValues
			};
		})
		.addCase(error, (state, { payload }) => ({
			...state,
			error: payload
		}))
		.addCase(clearError, (state, action) => ({
			...state,
			error: undefined
		}))
		.addCase(editSiteCode, (state, action) => ({
			...state,
			error: undefined,
			siteCodeEditable: true
		}))
		.addCase(updateSiteCode, (state, { payload }) => ({
			...state,
			siteCode: payload,
			siteCodeEditable: false
		}))
		.addCase(updateLoginType, (state, { payload }) => ({
			...state,
			loginType: payload
		}))
		.addCase(revertSiteCode, (state, action) => ({
			...state,
			siteCodeEditable: false
		}))
		.addCase(setAutoFillValues, (state, { payload: autoFillValues }) => ({
			...state,
			autoFillValues,
			isEducator: false
		}))
		.addCase(setIsEducator, (state, { payload }) => {
			const isEducator = payload;
			return {
				...state,
				error: undefined,
				isEducator
			};
		})
		.addCase(setInvalidCredentials, (state, { payload }) => ({
			...state,
			invalidCredentials: payload
		}))
		.addCase(setTooManyAttempts, (state, { payload }) => ({
			...state,
			tooManyAttempts: payload
		}))
		.addCase(setDurationSecs, (state, { payload }) => ({
			...state,
			durationSecs: payload
		}))
		.addCase(updateUsername, (state, { payload }) => ({
			...state,
			username: payload
		}))
		.addCase(validateSiteCode, state => ({
			...state,
			error: undefined
		}))
);

export default reducer;

/**
 * Epics
 */

export function lookupSiteCodeEpic(action$: Observable<PayloadAction<{ email: string }> & { meta: () => void }>) {
	return action$.pipe(
		ofType(lookupSiteCode.type),
		switchMap(({ meta: callback, payload }) => {
			const { email } = payload;
			return Auth.lookupSiteCode(email).pipe(
				map(response => forgotSiteCodeReceived(response)),
				tap(() => {
					callback && callback();
				})
			);
			catchError(error => of(forgotSiteCodeError(error)));
		})
	);
}

export function validateSiteCodeEpic(action$: Observable<PayloadAction<string>>) {
	return action$.pipe(
		ofType(validateSiteCode.type),
		switchMap(({ payload: sitecode }) =>
			Auth.validateSiteCode(sitecode).pipe(
				map(() => updateSiteCode(sitecode)),
				catchError(err => {
					const { body, status } = err;
					if (status !== 400 && status !== 404 && (!body || body.error !== 'invalid_grant')) {
						console.error(err);
					}
					return of(
						error({
							status,
							body
						})
					);
				})
			)
		)
	);
}
