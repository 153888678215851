import { parse } from 'bowser';

export enum UserAgentEnum {
	IOS = 'ios',
	KINDLE = 'kindle',
	ANDROID = 'android',
	HTML5 = 'html5'
}

export function parseUserAgent(): UserAgentEnum {
	const info = parse(window.navigator.userAgent);
	if (
		/(ios)/i.test(info.os?.name ?? '') ||
		/(ipad)/i.test(info.platform?.model ?? '') ||
		/(iphone)/i.test(info.platform?.model ?? '') ||
		(/(mac)/i.test(info.os?.name ?? '') && !/(chrome)/i.test(info.browser?.name ?? ''))
	) {
		return UserAgentEnum.IOS;
	}
	if (/(amazon)/i.test(info.platform?.vendor ?? '') || /(kindle)/i.test(info.platform?.model ?? '')) {
		return UserAgentEnum.KINDLE;
	}
	if (/(android)/i.test(info.os?.name ?? '') || /(android)/i.test(info.browser?.name ?? '')) {
		return UserAgentEnum.ANDROID;
	}
	return UserAgentEnum.HTML5;
}
