import { Dictionary } from '../types/dictionary';
import { Environment } from './environments';
import { Products } from './products';

// Staff URLS
const PORTAL_URLS: Dictionary<Dictionary<string>> = {
	[Environment.Development]: {
		[Products.ILE]: 'https://test.my.imaginelearning.com/dashboard',
		[Products.Spanish]: 'https://test.my.imaginelearning.com/espanol',
		[Products.Galileo]: 'https://aws-dev.ati-online.com/StudentCenter/ILToGalileoRedirect/Index',
		[Products.Reader]: 'https://test.my.imaginelearning.com/reading',
		[Products.Lectura]: 'https://local.my.imaginelearning.com:50000/lectura',
		[Products.MyPathMath]: 'https://app.dev.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathReading]: 'https://app.dev.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Traverse]: 'https://app.traverse-test.com/api/il-launch',
		[Products.Science]: 'https://app.dev.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Purpose]: 'https://app.dev.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathPortal]: 'https://app.dev.edgenuityapp.com',
		[Products.Robotify]: 'https://robotify.robo-dev.il-consumer.com/oauth/initiate_readiness_sso'
	},
	[Environment.Test]: {
		[Products.ILE]: 'https://test.my.imaginelearning.com/dashboard',
		[Products.Spanish]: 'https://test.my.imaginelearning.com/espanol',
		[Products.Galileo]: 'https://aws-dev.ati-online.com/StudentCenter/ILToGalileoRedirect/Index',
		[Products.Reader]: 'https://test.my.imaginelearning.com/reading',
		[Products.Lectura]: 'https://test.my.imaginelearning.com/lectura',
		[Products.MyPathMath]: 'https://app.qa.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathReading]: 'https://app.qa.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Traverse]: 'https://app.traverse-test.com/api/il-launch',
		[Products.Science]: 'https://app.qa.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Purpose]: 'https://app.qa.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathPortal]: 'https://app.qa.edgenuityapp.com',
		[Products.Robotify]: 'https://robotify.robo-dev.il-consumer.com/oauth/initiate_readiness_sso'
	},
	[Environment.Rc]: {
		[Products.ILE]: 'https://rc.my.imaginelearning.com/dashboard',
		[Products.Spanish]: 'https://rc.my.imaginelearning.com/espanol',
		[Products.Galileo]: 'https://galileodev.ati-online.com/StudentCenter/ILToGalileoRedirect/Index',
		[Products.Reader]: 'https://rc.my.imaginelearning.com/reading',
		[Products.Lectura]: 'https://rc.my.imaginelearning.com/lectura',
		[Products.MyPathMath]: 'https://app.staging.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathReading]: 'https://app.staging.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Traverse]: 'https://app.traverse-staging.com/api/il-launch',
		[Products.Science]: 'https://app.staging.edgenuityapp.com/launch?appfrom=mpp',
		[Products.Purpose]: 'https://app.staging.edgenuityapp.com/launch?appfrom=mpp',
		[Products.MyPathPortal]: 'https://app.staging.edgenuityapp.com',
		[Products.Robotify]: 'https://robotify.robo-staging.il-consumer.com/oauth/initiate_readiness_sso'
	},
	[Environment.Production]: {
		[Products.ILE]: 'https://my.imaginelearning.com/dashboard',
		[Products.Spanish]: 'https://my.imaginelearning.com/espanol',
		[Products.Galileo]: 'https://www.assessmenttechnology.com/StudentCenter/ILToGalileoRedirect/Index',
		[Products.Reader]: 'https://my.imaginelearning.com/reading',
		[Products.Lectura]: 'https://my.imaginelearning.com/lectura',
		[Products.MyPathMath]: 'https://app.edgenuity.com/launch?appfrom=mpp',
		[Products.MyPathReading]: 'https://app.edgenuity.com/launch?appfrom=mpp',
		[Products.Traverse]: 'https://learn.traverse-app.com/api/il-launch',
		[Products.Science]: 'https://app.edgenuity.com/launch?appfrom=mpp',
		[Products.Purpose]: 'https://app.edgenuity.com/launch?appfrom=mpp',
		[Products.MyPathPortal]: 'https://app.edgenuity.com',
		[Products.Robotify]: 'https://edu.robotify.com/oauth/initiate_readiness_sso'
	}
};

export default PORTAL_URLS;
