import { PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, timeout, withLatestFrom } from 'rxjs/operators';
import { launchProductEvent } from '../../services/caliperService';
import { selectAccessToken, selectRole, selectUser } from '../selectors/app';
import { Products } from '../../constants/products';
import { UrlMap } from '../../types/urlMap';
import { Environment } from '../../constants/environments';
import { Dictionary } from '../../types/dictionary';
import getLaunchUrl from '../../services/productLaunch';
import { launchProductAfterCaliper } from './productLauncher';

/**
 * Action creators
 */

export const caliperSessionEvent = createAction(
	'universal-login-page/caliper/CALIPER_SESSION_EVENT',
	(
		product: Products | string,
		urls: UrlMap,
		environment: Environment,
		addQuery: boolean,
		productQueryStrings: Dictionary<string>,
		isStudent: boolean
	) => ({
		payload: {
			product,
			urls,
			environment,
			addQuery,
			productQueryStrings,
			isStudent
		}
	})
);

/**
 * Reducer
 */

export interface CaliperState {
	caliperEventWasSent: boolean;
}

const initialState: CaliperState = {
	caliperEventWasSent: false
};

const reducer = createReducer(initialState, builder =>
	builder.addCase(caliperSessionEvent, state => ({
		...state,
		caliperEventWasSent: true
	}))
);

export default reducer;

/**
 * Epics
 */

export const missingDataError = 'missing required data to send event';

export function caliperSessionEventEpic(
	action$: Observable<
		PayloadAction<{
			product: Products;
			urls: UrlMap;
			environment: Environment;
			addQuery: boolean;
			productQueryStrings: Dictionary<string>;
			isStudent: boolean;
		}>
	>,
	state$: StateObservable<RootStateOrAny>
) {
	return action$.pipe(
		ofType(caliperSessionEvent.type),
		withLatestFrom(state$),
		switchMap(([{ payload }, state]) => {
			const { addQuery, environment, isStudent, product, productQueryStrings, urls } = payload;

			const url = getLaunchUrl(product, urls, environment as Environment, addQuery, productQueryStrings) ?? '';
			const { studentId } = selectUser(state) || {};
			const token = selectAccessToken(state) ?? '';
			const role = selectRole(state) ?? '';

			return launchProductEvent(studentId!, product, url, token!, isStudent, role).pipe(
				timeout(3000),
				map(({ successful, requestId }) =>
					launchProductAfterCaliper(product, urls, environment, addQuery, productQueryStrings, isStudent)
				),
				catchError(async error => {
					console.error('event send error:', error);
					return launchProductAfterCaliper(product, urls, environment, addQuery, productQueryStrings, isStudent);
				})
			);
		})
	);
}

export const CALIPER_EPICS = [caliperSessionEventEpic];
