import { Action, Middleware, PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';
import sortBy from 'lodash-es/sortBy';
import { ofType, StateObservable } from 'redux-observable';
import { RootStateOrAny } from 'react-redux';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, map, withLatestFrom, timeout } from 'rxjs/operators';
import { AssessmentProviders } from '../../constants/assessmentProviders';
import { AssessmentStatuses } from '../../constants/assessmentStatuses';
import fakeProducts from '../../constants/fakeProducts';
import { ProductDisabledReasons } from '../../constants/productDisabledReasons';
import { PRODUCT_NAMES } from '../../constants/productNames';
import { Products } from '../../constants/products';
import {
	AssessmentLockout,
	getProductPermissions,
	ProductPermissionsAssessmentRecommended,
	ProductPermissionsProduct,
	ProductPermissionsSetupHelp
} from '../../services/permissions';
import { selectAccessToken, selectEnvironment, selectRole, selectUser } from '../../redux/selectors/app';
import { Product } from '../../types/product';
import { API_TIMEOUT_MS, EntityContext, evaluateUserBatch, EvaluationResult } from '../../services/flagr';
import { User } from '../../types/user';
import { UserFlags } from '../../constants/flags';
import { selectProducts, selectUserFlagEvaluationResults } from '../selectors/productSelection';
import determineUseNativeBehavior from './native';
import {
	identify as pendoIdentify,
	updateOptions as pendoUpdateOptions,
	initEducatorSnippet,
	initStudentSnippet
} from '../../services/pendo';
import { getAnalyticsUser } from '../../services/analyticsUser';
/**
 * Action creators
 */

export const getProducts = createAction('universal-login-page/productSelection/PRODUCT_PERMISSIONS_REQUEST');
export const productPermissionsAssessmentRecommendedReceived = createAction<ProductPermissionsAssessmentRecommended>(
	'universal-login-page/productSelection/PRODUCT_PERMISSIONS_ASSESSMENT_RECOMMENDED_RECEIVED'
);
export const productPermissionsError = createAction<Response | Error | object>(
	'universal-login-page/productSelection/PRODUCT_PERMISSIONS_ERROR'
);
export const productPermissionsProductsReceived = createAction<ProductPermissionsProduct[]>(
	'universal-login-page/productSelection/PRODUCT_PERMISSIONS_PRODUCTS_RECEIVED'
);
export const productPermissionsSetupHelpReceived = createAction<ProductPermissionsSetupHelp>(
	'universal-login-page/productSelection/PRODUCT_PERMISSIONS_SETUP_HELP_RECEIVED'
);
export const productPermissionsSuccess = createAction('universal-login-page/productSelection/PRODUCT_PERMISSIONS_SUCCESS');
export const fetchingUserFlags = createAction('universal-login-page/productSelection/FETCHING_USER_FLAGS');
export const flagEvaluationComplete = createAction<EvaluationResult[]>('universal-login-page/productSelection/FLAG_EVALUATION_COMPLETE');

export const analyticsCleanup = createAction('universal-login-page/productSelection/ANALYTICS_CLEANUP');
export const analyticsError = createAction<Error | object>('universal-login-page/productSelection/ANALYTICS_ERROR');
export const analyticsIdentifyUser = createAction(
	'universal-login-page/productSelection/ANALYTICS_IDENTIFY_USER',
	(token: string, resetDate?: string) => ({
		payload: {
			token,
			resetDate
		}
	})
);
export const analyticsUpdateOptions = createAction(
	'universal-login-page/productSelection/ANALYTICS_UPDATE_OPTIONS',
	(token: string, resetDate?: string) => ({
		payload: {
			token,
			resetDate
		}
	})
);
export const analyticsUserUpdated = createAction('universal-login-page/productSelection/ANALYTICS_USER_UPDATED');
export const analyticsInitialize = createAction('universal-login-page/productSelection/ANALYTICS_INITIALIZED', role => {
	const payload = role.toUpperCase() === 'STUDENT' ? initStudentSnippet() : initEducatorSnippet();
	return { payload };
});
export const analyticsUserIdentified = createAction('universal-login-page/productSelection/ANALYTICS_USER_IDENTIFIED', canceller => ({
	payload: [canceller]
}));
/**
 * Reducer
 */

export interface ProductSelectionState {
	loading: boolean;
	flagsLoaded: boolean;
	products?: Product[];
	productsError?: Response | Error | object;
	recommendedAssessmentProvider?: AssessmentProviders;
	recommendedAssessmentUrl?: string;
	evaluationResults?: EvaluationResult[];
	rosterDashboard?: Products;
	scheduleDashboard?: Products;
	setupRequired: boolean;
	analyticsInitialized: boolean;
	analyticsUserIdentified: boolean;
}

const initialState: ProductSelectionState = {
	loading: false,
	flagsLoaded: false,
	setupRequired: false,
	analyticsInitialized: false,
	analyticsUserIdentified: false
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(productPermissionsError, (state, action) => ({
			...state,
			loading: false,
			productsError: action.payload
		}))
		.addCase(productPermissionsProductsReceived, (state, action) => {
			const allProducts = action.payload || [];

			const products = sortBy(
				allProducts
					// Filter out IM and IMF, that don't use universal login yet.
					.filter(({ product }) => product !== Products.IM && product !== Products.IMF)
					// Then transform into a model that fits product selection:
					// { id, name, enabled, assessmentRequired, assessmentProvider, assessmentUrl }
					.map(prod => {
						const { assessmentLockout, disabledReason, product, status } = prod;
						const { status: assessmentStatus, provider, url: assessmentUrl } = assessmentLockout || ({} as AssessmentLockout);
						const enabled = /enabled/i.test(status || '');
						const assessmentRequired =
							!enabled &&
							product !== Products.Spanish &&
							(disabledReason === ProductDisabledReasons.NotRosteredWithNWEA ||
								disabledReason === ProductDisabledReasons.PendingAssessmentLockout) &&
							assessmentStatus !== AssessmentStatuses.Complete &&
							assessmentStatus !== AssessmentStatuses.Exempt;
						const assessmentProvider =
							provider ||
							(disabledReason === ProductDisabledReasons.NotRosteredWithNWEA ? AssessmentProviders.NWEA : undefined);
						return {
							assessmentProvider,
							assessmentRequired,
							assessmentStatus,
							assessmentUrl,
							disabledReason,
							enabled,
							id: product,
							name: PRODUCT_NAMES[product] || product,
							product
						} as Product;
					}),
				(prod: Product) => prod.name.toLowerCase()
			);

			return {
				...state,
				products
			};
		})
		.addCase(getProducts, state => ({
			...state,
			loading: true
		}))
		.addCase(productPermissionsSetupHelpReceived, (state, action) => {
			const setupRequired = !!action.payload;
			const { rosterDashboard, scheduleDashboard } = action.payload || {};
			return { ...state, rosterDashboard, scheduleDashboard, setupRequired };
		})
		.addCase(productPermissionsAssessmentRecommendedReceived, (state, action) => {
			const { provider: recommendedAssessmentProvider, url: recommendedAssessmentUrl } = action.payload || {};
			return {
				...state,
				recommendedAssessmentProvider,
				recommendedAssessmentUrl
			};
		})
		.addCase(productPermissionsSuccess, state => ({
			...state,
			loading: false
		}))
		.addCase(fetchingUserFlags, state => ({
			...state,
			flagsLoaded: false
		}))
		.addCase(flagEvaluationComplete, (state, action) => {
			return {
				...state,
				flagsLoaded: true,
				evaluationResults: action.payload
			};
		})
		.addCase(analyticsInitialize, (state, action) => ({
			...state,
			analyticsInitialized: action.payload
		}))
		.addCase(analyticsUserIdentified, (state, action) => ({
			...state,
			analyticsUserIdentified: true
		}))
);

export default reducer;

/**
 * Epics
 */

export function getProductsAfterFlagsEpic(action$: Observable<Action>, state$: StateObservable<RootStateOrAny>) {
	return action$.pipe(
		ofType(flagEvaluationComplete.type),
		withLatestFrom(state$),
		switchMap(([_, state]) => {
			const token = selectAccessToken(state) ?? '';
			const role = selectRole(state) ?? '';
			const { studentId: userId } = selectUser(state) || ({} as User);

			const evaluationResults = selectUserFlagEvaluationResults(state);
			const enableRobotifyFlag = evaluationResults?.find(({ flagKey }) => flagKey === UserFlags.EnableRobotify);
			const enableRobotify = enableRobotifyFlag?.variantKey === 'on';

			// Do not filter based on agent. We want to show all products to all devices.
			// const isNative = determineUseNativeBehavior(role, evaluationResults);
			// const studentExcludedProducts =
			// 	role === 'Student' && isNative
			// 		? Object.values(Products).filter(product => product !== Products.ILE && product !== Products.Spanish)
			// 		: [];
			// const userExcludedProducts = !enableRobotify ? studentExcludedProducts.concat(Products.Robotify) : studentExcludedProducts;

			const userExcludedProducts = !enableRobotify ? [Products.Robotify] : [];
			const adminExcludedProducts = userExcludedProducts.concat(Products.AdaptiveAssessment);

			// Retrieving products for ILAdmin and ILSiteAdmin users is too expensive.
			// We'll just give them all of them.
			if (/il(site)?admin/i.test(role)) {
				const products = Object.values(Products)
					// Not including AdaptiveAssessment right now to keep it on the DL
					.filter(product => !adminExcludedProducts.includes(product))
					.map(prod => ({ product: prod, status: 'Enabled' } as ProductPermissionsProduct));
				return from([productPermissionsSuccess(), productPermissionsProductsReceived(products)]);
			}

			// Bypass permissions service for development/testing
			if (/true|yes|on|1/i.test(process.env.REACT_APP_BYPASS_PRODUCT_PERMISSIONS || '')) {
				const productNames = JSON.parse(process.env.REACT_APP_SHOW_PRODUCTS || '') || Object.keys(fakeProducts);
				const products = productNames.map((prod: string) => fakeProducts[prod] as ProductPermissionsProduct);
				return from([productPermissionsSuccess(), productPermissionsProductsReceived(products)]);
			}

			return getProductPermissions(userId, token).pipe(
				switchMap(({ products, setupHelp, assessmentRecommended }) => {
					const allowedProducts = products.filter(p => !userExcludedProducts.includes(p.product));
					const actions: Action[] = [productPermissionsSuccess(), productPermissionsProductsReceived(allowedProducts)];
					if (setupHelp) {
						actions.push(productPermissionsSetupHelpReceived(setupHelp));
					}
					if (assessmentRecommended) {
						actions.push(productPermissionsAssessmentRecommendedReceived(assessmentRecommended));
					}
					return from(actions);
				}),
				catchError(err => of(productPermissionsError(err)))
			);
		})
	);
}

export function getUserFlagsEpic(action$: Observable<Action>, state$: StateObservable<RootStateOrAny>) {
	return action$.pipe(
		ofType(getProducts.type),
		withLatestFrom(state$),
		switchMap(([_, state]) => {
			const { sites, studentId: userId, userName } = selectUser(state) || ({} as User);
			const env = selectEnvironment(state);
			const role = selectRole(state);
			// Append environment if necessary.
			const sitecode = env && env !== 'production' ? `${sites}@${env}` : sites;

			// The entity context is the information about the user that might be relevant.
			const entityContext = {
				sitecode: sitecode,
				userId: userId,
				userName: userName,
				role: role
			} as EntityContext<string>;

			// Failure to evaluate is actually okay, and shouldn't block progress at all.
			return evaluateUserBatch(entityContext, userId, sitecode).pipe(
				timeout(API_TIMEOUT_MS),
				map(({ evaluationResults }) => flagEvaluationComplete(evaluationResults)),
				catchError(async error => {
					const emptyResults: EvaluationResult[] = [];
					console.error('error retrieving flags');
					return flagEvaluationComplete(emptyResults);
				})
			);
		})
	);
}

export function analyticsIdentifyUserEpic(
	action$: Observable<PayloadAction<{ token: string; resetDate?: string }>>,
	state$: StateObservable<RootStateOrAny>
) {
	return action$.pipe(
		ofType(analyticsIdentifyUser.type),
		withLatestFrom(state$),
		switchMap(([{ payload: { token, resetDate } }, state]) =>
			getAnalyticsUser(token).pipe(
				map(analyticsUser => {
					const products = selectProducts(state);
					const canceller = pendoIdentify(analyticsUser, products, resetDate);
					return analyticsUserIdentified(canceller);
				}),
				catchError(err => of(analyticsError(err)))
			)
		)
	);
}

export function analyticsUpdateOptionsEpic(
	action$: Observable<PayloadAction<{ token: string; resetDate?: string }>>,
	state$: StateObservable<RootStateOrAny>
) {
	return action$.pipe(
		ofType(analyticsUpdateOptions.type),
		withLatestFrom(state$),
		switchMap(([{ payload: { token, resetDate } }, state]) =>
			getAnalyticsUser(token).pipe(
				map(analyticsUser => {
					const products = selectProducts(state);
					pendoUpdateOptions(analyticsUser, products, resetDate);
					return analyticsUserUpdated();
				}),
				catchError(err => of(analyticsError(err)))
			)
		)
	);
}

/**
 * Middleware
 */

export function createAnalyticsMiddleware(): Middleware {
	let cancellers: Array<() => void> = [];

	return store => next => action => {
		if (action.type === analyticsUserIdentified.type) {
			cancellers.push(action.payload);
			return next(analyticsUserIdentified(undefined));
		}
		if (action.type === analyticsCleanup.type) {
			cancellers.forEach(fn => fn());
			cancellers = [];
		} else if (action.type === analyticsError.type) {
			console.error('Analytics error:', action.payload);
		}
		return next(action);
	};
}
