import { UserFlags } from '../../constants/flags';
import { EvaluationResult } from '../../services/flagr';
import { UserAgentEnum, parseUserAgent } from '../../services/useragent';

export default function determineUseNativeBehavior(role: string | undefined, evaluationResults: EvaluationResult[] | undefined) {
	const userAgent = parseUserAgent();
	const enableNativeAppFlag = evaluationResults?.find(({ flagKey }) => flagKey === UserFlags.EnableNativeApp);
	const enableNativeApp = enableNativeAppFlag?.variantKey === 'on';
	const isNative =
		role === 'Student' &&
		enableNativeApp &&
		(userAgent === UserAgentEnum.IOS || userAgent === UserAgentEnum.ANDROID || userAgent === UserAgentEnum.KINDLE);
	return isNative;
}
