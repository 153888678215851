import {
	Caliper,
	CaliperAction,
	createInstructor,
	createSessionEvent,
	createSoftwareApplication,
	createStudent,
	createUser,
	Sensor
} from '@imaginelearning/caliper-ts';
import URLS, { VERSIONS } from './../constants/urls';
import { Environment } from '../constants/environments';
import { getEnvironment } from './network';
import { ApiServices } from '../constants/apiServices';
import { Observable } from 'rxjs';
import http from './http';
import { Products } from '../constants/products';

export interface CaliperEventResponse {
	failed: any;
	requestId: any;
	successful: any;
}

export function launchProductEvent(
	userId: string,
	product: Products,
	productUrl: string,
	token: string,
	isStudent: boolean,
	role: string
): Observable<CaliperEventResponse> {
	let actor;
	if (isStudent) {
		actor = createStudent({
			id: Caliper.uuid(userId)
		});
	} else if (role === 'Teacher') {
		actor = createInstructor({
			id: Caliper.uuid(userId)
		});
	} else {
		actor = createUser({
			id: Caliper.uuid(userId)
		});
	}

	const productSelectionUrl = URLS[getEnvironment()][ApiServices.Redirect];
	Caliper.settings.applicationUri = productSelectionUrl;
	let sensor = new Sensor(productSelectionUrl, {
		edApp: createSoftwareApplication({
			id: productSelectionUrl
		}),
		validationEnabled: true
	});

	// Replace every queryParam after the .com/, append the product name at the end.
	productUrl = new URL(productUrl).origin + `/${product}`;

	const object = createSoftwareApplication({
		id: productUrl
	});

	const event = sensor.createEvent(createSessionEvent, {
		actor,
		action: CaliperAction.Launched,
		object
	});
	event.eventTime = new Date().toJSON();

	event['@context'].push(
		`${URLS[getEnvironment()][ApiServices.ContextEnvironment]}session-launched/${
			VERSIONS[getEnvironment()][ApiServices.ContextEnvironment]
		}`
	);

	const envelope = sensor.createEnvelope({
		data: [event]
	});

	const caliperUrl = URLS[getEnvironment() || Environment.Production][ApiServices.Caliper];
	const response = http(caliperUrl)
		.body(envelope)
		.bearer(token)
		.contentType('application/json')
		.post<CaliperEventResponse>();

	return response;
}
